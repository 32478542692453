<template>
    <div class="roadFront">
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划" v-if="roleName !== '干线公路'">
                                        <el-cascader :key="parseInt(Math.random()*10000)" style="width: 160px" size="medium" v-model="region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable ></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 160px" size="medium" v-model="xmmc" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目类型">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">删除</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button v-if="isAdmin" @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">新增</el-button>
                                </span>
                                <h3 class="tit">公路列表</h3>
                            </div>
                            <div class="box-content">

                                <el-table  @sort-change="changeSort" @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">
                                    <el-table-column fixed prop="" align="center" :width="135" label="操作">
                                        <template slot-scope="scope">
                                            <span class="newicon iconsyes" @click="showMaps(scope.row)"><i class="el-icon-location icons iconsyes"></i>定位</span>
                                            <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>
<!--                                            <el-popconfirm v-if="!scope.row.IsLock" style="margin:0;display: inline-block;" confirm-button-text='确认' @confirm="changeLock(scope.row)" cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="是否锁定该项目？锁定后需管理员解锁?">-->
<!--                                                <template #reference>-->
<!--                                                    <div class="newicon iconsyes" ><i class="el-icon-edit-outline icons iconsyes"></i>锁定</div>-->
<!--                                                </template>-->
<!--                                            </el-popconfirm>-->
<!--                                            <el-popconfirm v-else style="margin:0;display: inline-block;" confirm-button-text='确认' @confirm="changeLock(scope.row)" cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="是否解锁该项目?">-->
<!--                                                <template #reference>-->
<!--                                                    <div class="newicon iconsyes" ><i class="el-icon-edit-outline icons iconsyes"></i>解锁</div>-->
<!--                                                </template>-->
<!--                                            </el-popconfirm>-->
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                    <el-table-column prop="BuildAddress"  label="所在地市" :width="100" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            {{scope.row.BuildAddress.split(',')[0]}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="BuildAddress" label="所属县（市）" :width="120" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            {{scope.row.BuildAddress.split(',')[1]}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="RouteName" label="路线名称" align="center" show-overflow-tooltip :width="350"></el-table-column>
                                    <el-table-column prop="RouteCode" label="路线编码" align="center" show-overflow-tooltip :width="100"></el-table-column>
                                    <el-table-column prop="ProjectType" label="项目类型" align="center" show-overflow-tooltip :width="200"></el-table-column>
                                    <el-table-column prop="StartDate" label="开工年" align="center" show-overflow-tooltip >
                                        <template slot-scope="scope">
                                            {{scope.row.StartDate.slice(0,4)}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="EndDate" label="完工年" align="center" show-overflow-tooltip >
                                        <template slot-scope="scope">
                                            {{scope.row.EndDate.slice(0,4)}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="BuildLevel" label="拟建等级" align="center" show-overflow-tooltip ></el-table-column>
                                    <el-table-column prop="BuildScale" label="建设规模(公里)" align="right" :width="120" show-overflow-tooltip ></el-table-column>
                                    <el-table-column prop="NewLand" label="新增用地(公顷)" align="right" :width="120" show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="BuildNature" label="建设性质" align="center" show-overflow-tooltip ></el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <!--            详情弹窗-->
            <el-drawer :visible.sync="showAddModel" @close="closeDrawer" size="1183px" :title="XMMC"  class="editModal">
                <el-form  :hide-required-asterisk="true" :model="detail" :inline-message="true" ref="detailForm"  :inline="true" class="form-inline" v-loading="uploadLoading">
                            <el-row>
                                <el-form-item label="路线名称" label-width="168px" prop="routeName" :rules="[{ required: true, message:'请输入路线名称',trigger: 'blur' }]">
                                    <el-input placeholder="请输入路线名称" v-model="detail.routeName" ></el-input>
                                </el-form-item>
                                <el-form-item label="路线编码" label-width="168px" prop="routeCode">
                                    <el-input placeholder="请输入路线编码" v-model="detail.routeCode"></el-input>
                                </el-form-item>
                                <el-form-item label="项目类型" label-width="168px" prop="projectType" :rules="[{ required: true, message:'请选择项目类型',trigger: 'change' }]">
                                    <el-select placeholder="请选择项目类型" v-model="detail.projectType" >
                                        <el-option label="乡镇通三级及以上公路" value="乡镇通三级及以上公路"></el-option>
                                        <el-option label="较大人口规模自然村通硬化路" value="较大人口规模自然村通硬化路"></el-option>
                                        <el-option label="建制村通双车道" value="建制村通双车道"></el-option>
                                        <el-option label="资源路" value="资源路"></el-option>
                                        <el-option label="旅游路" value="旅游路"></el-option>
                                        <el-option label="产业路" value="产业路"></el-option>
                                        <el-option label="公路提质改造" value="公路提质改造"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="开工年" label-width="168px">
                                    <el-date-picker  style="width: 178px"

                                                     format="yyyy"
                                                     value-format="yyyy-MM-dd"
                                                     v-model="detail.startDate" type="year" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="完工年" label-width="168px">
                                    <el-date-picker  style="width: 178px"

                                                     format="yyyy"
                                                     value-format="yyyy-MM-dd"
                                                     v-model="detail.endDate" type="year" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="拟建等级" label-width="168px" prop="buildLevel" :rules="[{ required: true, message:'请选择拟建等级',trigger: 'change' }]">
                                    <el-select placeholder="请选择拟建等级" v-model="detail.buildLevel">
                                        <el-option label="二级" value="二级"></el-option>
                                        <el-option label="三级" value="三级"></el-option>
                                        <el-option label="四级" value="四级"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="建设规模" label-width="168px" prop="buildScale"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                    <el-input placeholder="建设规模" v-model="detail.buildScale"><template #suffix>公里</template></el-input>
                                </el-form-item>
                                <el-form-item label="新增用地" label-width="168px" prop="newLand" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                    <el-input placeholder="新增用地" v-model="detail.newLand"><template #suffix>公顷</template></el-input>
                                </el-form-item>
                                <el-form-item label="建设性质" label-width="168px"  prop="buildNature" :rules="[{ required: true, message:'请选择建设性质',trigger: 'change' }]">
                                    <el-select placeholder="请选择项目类别" v-model="detail.buildNature">
                                        <el-option label="新建" value="新建"></el-option>
                                        <el-option label="改扩建" value="改扩建"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="建设位置" label-width="168px" prop="buildAddress"  :rules="[{ required: true, message:'请选择建设位置',trigger: 'change' }]">
                                    <el-cascader :key="parseInt(Math.random()*10000)" style="width: 160px" size="medium" v-model="detail.buildAddress" :options="regions" :props="{  expandTrigger: 'hover' }" clearable ></el-cascader>
                                </el-form-item>
                            </el-row>

                    <div class="footer">
                        <el-button type="primary" @click="save">保存</el-button>
                        <el-button @click="cancel">取消</el-button>
                    </div>
                </el-form>
            </el-drawer>
        </div>
        <el-dialog
                :visible.sync="showMessage"
                title="提示"
                width="30%"
        >
            <span>当前页面未保存,是否确定退出?</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="showMessage = false">取消</el-button>
        <el-button type="primary" @click="closeEdit">确认</el-button>
      </span>
            </template>
        </el-dialog>

        <!--建设单位编辑弹窗-->
        <el-dialog :title="dialogTableVisibleData.RouteName" :visible.sync="dialogTableVisibleMap" width="100%" class="mapdialog mapdialog1">
            <PlanForm :key="parseInt(Math.random()*10000)" v-if="dialogTableVisibleMap"  :dialogData="dialogTableVisibleData" />
        </el-dialog>
    </div>
</template>
<script>
    import PlanForm from "./frontMap/countrySideMap";
    import { exportData } from "../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../store/index'
    import http from "../api/http";
    export default {
        name: "countrySide",
        components: {PlanForm},
        data() {
            return {
                dialogTableVisibleMap:false,
                dialogTableVisibleData:{},
                roleName:'',
                required: false,
                title:'新增建设单位',
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                detail:{},
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                XMMC:'',
                addStatus: true,
                tableHeight:0, //表格高度
                /**查询条件 begin*/
                xmmc: "", //项目名称
                checkedJsxz: [], //建设性质
                checkedXmlx: [], //项目类型
                region: [""], //已选中行政区划
                /**查询条件 end*/
                checkList: [],
                jsxzList: [{value:'新建',label:'新建',disabled: false},{value:'改扩建',label:'改扩建',disabled: false}],
                xmlxList: [{value:'乡镇通三级及以上公路',label:'乡镇通三级及以上公路',disabled: false},{value:'较大人口规模自然村通硬化路',label:'较大人口规模自然村通硬化路',disabled: false},{value:'建制村通双车道',label:'建制村通双车道',disabled: false},{value:'资源路',label:'资源路',disabled: false},{value:'旅游路',label:'旅游路',disabled: false},{value:'产业路',label:'产业路',disabled: false},{value:'公路提质改造',label:'公路提质改造',disabled: false}],
                currentPage: 1, //当前页
                currentPage1: 1, //当前页
                pageSize: 30, //每页记录数
                pageSize1: 10, //每页记录数
                regions: [
                    {
                        value: "",
                        label: "黑龙江省",
                    },
                ],
                tableData: [], //请求列表
                total: 0, //请求记录数
                searchAll:[],
                newAdd:false,
                showEdit:false,
                isAdmin: false,
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
            };
        },
        mounted() {
            var that = this
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height - 150;
            }, 100);
            this.getRegion();
            this.search();
            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.roleName = res.data.roleName
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
            })
            // this.getCompanyList()
            // this.getFRDW()
        },
        computed:{

        },
        methods: {
            showMaps(row) {
                this.http.post('/api/Ncgl_flag/getItemById?id='+row.ID,{}).then(res=>{
                    // if(res.status){
                    row.XZFW = res.XZFW
                    row.BaseInfoID = res.BaseInfoID ? res.BaseInfoID : row.ID
                    row.QQID = res.ID!='00000000-0000-0000-0000-000000000000' ? res.ID : row.ID
                    this.dialogTableVisibleData = row;
                    this.dialogTableVisibleMap = true;
                    // }else{
                    //     this.$message.error(res.message)
                    // }
                })
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },
            changeSort(val) {
                if (val.order) {
                    this.sortData = {
                        field: val.prop,
                        sort: val.order == "ascending" ? "asc" : "desc",
                    };
                    if (val.prop == "SZDS") {
                        this.sortData.field = "DSBM";
                    }
                    if (val.prop == "SZX") {
                        this.sortData.field = "QXBM";
                    }
                } else {
                    this.sortData = {
                        field: "XMXH",
                        sort: "asc",
                    };
                }
                this.tableData = [];
                this.search();
            },

            closeDrawer(){
                this.$refs.detailForm.resetFields()
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                var reg =  /^[+]{0,1}(\d+)$/
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkNum  (rule, value, callback)  {
                if (!value) {
                    callback();
                }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                // if (this.newAdd == false){
                //
                //     return false
                // }
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            confirmChoose(){

            },
            addRoad(){
                var that = this
                this.addStatus = true
                this.currentPage1 = 1
                this.showAddModel = true
                this.detail = {}
            },
            //退出编辑
            closeEdit(){
                this.showMessage = false
                this.showEditModel = false
                this.showAddModel = false
                this.detail = {}
            },
            //删除图片
            deleted(index,type){
                this.detail[type].pfwj.splice(index,1)
            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            save(){
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        params.newLand = params.newLand == '' ?  null : Number(params.newLand)
                        params.buildScale = params.buildScale == '' ? null : Number(params.buildScale)
                        params.buildAddress = params.buildAddress.join().replace(/,/g,",")
                        console.log(params)
                        if(this.addStatus){
                            params.id='00000000-0000-0000-0000-000000000000'
                            this.http.post('/api/Ncgl_base_info/AddBaseInfo',params).then(res=>{
                            if(res.status){
                                this.showAddModel = false
                                this.search()
                                this.$message.success(res.message)
                                this.$refs.detailForm.resetFields()
                            }else{
                                this.$message.error(res.message)
                            }
                        })
                    }else{
                            this.http.post('/api/Ncgl_base_info/UpdateBaseInfo',params).then(res=>{
                                if(res.status){
                                    this.showAddModel = false
                                    this.search()
                                    this.$message.success(res.message)
                                    this.$refs.detailForm.resetFields()
                                    this.addStatus = true
                                }else{
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    }
                });
            },
            cancel(){
                this.showMessage = true
                this.showEdit = false
                this.$refs.editForm.resetFields()

            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_high_national_early/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail[type].pfwj.push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
            openDetailsEdit(row) {
                var that = this
                this.showAddModel = true
                this.addStatus = false
                this.http.post('/api/Ncgl_base_info/getItemById?id='+row.ID,{}).then(res=>{
                    res.data.buildAddress = res.data.buildAddress.split(',')
                    this.detail = res.data
                    this.XMMC = res.data.routeName
                })
            },
            exportData() {
                var that = this
                this.http.post('/api/Plan_high_national_early/templateWrite',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '前期项目表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                var postData = { SZDS: "", SZX: "" };
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        res.map((r) => {
                            r.value = r.label;
                            r.children.map((rr) => {
                                rr.value = rr.label;
                            });
                        });
                        this.regions = res;

                    });
            },
            //查询
            search() {
                this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.http
                    .post(
                        "/api/Ncgl_base_info/GetPageData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        this.regions =  [
                            {
                                value: "",
                                label: "黑龙江省",
                            },
                        ]
                        this.getRegion()
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                        }
                    });
            },
            postData() {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_xmmc = {
                    Name: "RouteName",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "BuildAddress",
                    Value: szds+','+ssx,
                    DisplayType: "like",
                };
                //建设性质
                var jsgmArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    Name: "BuildNature",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
                //项目类型
                var xmlxArray = [];
                this.checkedXmlx.forEach((element) => {
                    xmlxArray.push(element);
                });
                var query_xmlx = {
                    Name: "ProjectType",
                    Value: xmlxArray.join(","),
                    DisplayType: "checkbox",
                };
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "DSBM",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_ssx,
                        query_xmmc,
                        query_xmlx,
                        query_jsgm
                    ]),
                };
                return postData;
            },
            clearCondition() {
                this.region = [""];
                this.xmmc = "";
                this.frdw = "";
                this.xmnd = "";
                this.qqgzjz = [];
                this.gllx = "";
                this.checkedXmlb = [];
                this.checkedJsgm = []
                this.checkedGk = [];
                this.checkedCbsj = [];
                this.checkedSgtsj = [];
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
        },
        watch: {
            activeIndex(v) {
                if (v) {
                    this.tableHeight = document.body.offsetHeight - 450;
                } else {
                    this.tableHeight = document.body.offsetHeight - 245;
                }
            },
        },
    };
</script>
<style>
    .hidden {
        display: none ;
    }
</style>
<style lang="less" scoped>
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .roadFront{
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin-right: 20px;
        }
        /deep/.el-collapse-item__content {
            padding:10px 15px 20px 15px  !important;
        }
        .editModal{
            /deep/.el-input__inner{
                width: 178px;
            }

        }
        .dwmcWrap{
            /deep/.el-input__inner{
                width: 350px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/ .formBox .el-input--suffix{
            width: 160px;
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #FAFAFA;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /deep/ .el-input--suffix{
        width: 178px;
    }
    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }

    /deep/.el-form-item__error--inline {
        position: relative;
        top: auto;
        display: block;
        left: auto;
        margin-left: 10px;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    /*/deep/.el-form-item__error--inline{*/
    /*    top: 14px;*/
    /*    left: 5px;*/
    /*    position: absolute;*/
    /*    background: #fff;*/
    /*    display: inline-block;*/
    /*    margin-left: 10px;*/
    /*}*/
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        cursor: pointer;
        width: 350px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 15px;
        border:1px solid #DCDFE6;
        border-radius: 2px;
        .dwmc{
            text-align: center;
            width: 330px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 40px;
            background: #fafafa;
        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 40px;
            background: #fafafa;
            i{
                font-size: 16px;
            }
        }
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }
    /deep/.el-dialog{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
</style>
